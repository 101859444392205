.p_error {
  padding: 10px;
  color: red;
  text-align: center;
  border-radius: 5px;
  font-size: 18px;
}

.a {
  border-bottom: 1px solid red;
}
.error {
  color: #000000;
  margin-left: 4px;
  position: relative;
  top: 3px;
  font-size: 15px;
  margin-bottom: -4px;
}

.transparent {
  background: transparent !important;
  margin: 0px !important;
}
