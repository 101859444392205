.btn_primary {
  padding: 10px 16px;
  min-width: 9.188rem;
  border-radius: 1.5rem;
  color: white !important;
  font-family: inherit;
  font-size: 0.813rem;
  font-weight: 700;
  line-height: 1.813rem;
  background: orange !important;
  border-color: orange !important;
  cursor: pointer;
}

.border {
  border-radius: 23px;
}

.shadow {
  -webkit-box-shadow: 2px 2px 13px hsl(0deg 0% 53% / 64%);
  box-shadow: 2px 2px 13px hsl(0deg 0% 53% / 64%);
}

.linea {
  -webkit-box-shadow: 2px 2px 4px #888;
  box-shadow: 2px 2px 4px #888;
  background-color: #30cfc2 !important;
  border-radius: 10px !important;
  height: 10px !important;
  width: 100px !important;
}

.btn_primary:focus {
  border: none;
  outline: -webkit-focus-ring-color auto 0px;
}

.btn_white {
  padding: 10px 16px;
  min-width: 9.188rem;
  min-height: 3.125rem;
  border-radius: 1.5rem;
  color: #a833ff !important;
  font-family: inherit;
  font-size: 0.813rem;
  font-weight: 700;
  line-height: 1.813rem;
  text-transform: uppercase;
  background: white !important;
  border: 1px solid #a833ff !important;
  cursor: pointer;
}

.btn_white:focus {
  border: none;
  outline: -webkit-focus-ring-color auto 0px;
}

.btn_orange {
  padding: 10px 16px;
  min-width: 9.188rem;
  min-height: 3.125rem;
  border-radius: 1.5rem;
  color: white !important;
  font-family: inherit;
  font-size: 0.813rem;
  font-weight: 700;
  line-height: 1.813rem;
  text-transform: uppercase;
  background: #dc2e00 !important;
  border-color: #dc2e00 !important;
}

.primary {
  color: #a833ff;
}

.white {
  color: white !important;
}

.black {
  color: black !important;
}

@media (min-width: 600px) {
  .row {
    grid-template-columns: repeat(2, 1fr);
  }

  .row_2 {
    grid-template-columns: repeat(1, 1fr);
  }

  .row_4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 991px) {
  .padding_section2 {
    padding: 15px;
  }
  .centrado_responsive {
    text-align: center !important;
  }

  .margin_responsive {
    margin: 0 auto;
  }

  .p_responsive {
    text-align: center;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    width: 1127px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container {
    width: 933px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .container {
    width: 723px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    margin-left: 1em !important;
    margin-right: 1em !important;
    width: auto !important;
  }
}

@media (min-width: 991px) {
  .row {
    grid-template-columns: repeat(3, 1fr);
  }

  .row_2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .row_4 {
    grid-template-columns: repeat(4, 1fr);
  }

  .padding_left {
    padding-left: 50px;
  }

  .padding_pro {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .padding_section {
    padding: 20px;
  }

  .padding_section2 {
    padding: 40px;
  }
}

@media (max-width: 600px) {
  .h1 {
    font-size: 1.75rem !important;
    line-height: 2.125rem !important;
  }
}

.row_4 {
  display: grid;
  grid-template-rows: auto;
  grid-gap: 1rem;
  padding: 1rem;
  width: 100%;
}

.row_2 {
  display: grid;
  grid-template-rows: auto;
  grid-gap: 1rem;
  width: 100%;
}

.row {
  display: grid;
  grid-template-rows: auto;
  grid-gap: 1rem;
  padding: 1rem;
  width: 100%;
}

.grid_2_1 {
  grid-column: 1 / span 2;
  grid-row: 1;
}

.grid_item_1 {
  width: 100%;
  text-align: center;
}

.grid_item_2 {
  width: 100%;
  text-align: center;
}

.grid_item_3 {
  width: 100%;
  text-align: center;
}

.h4 {
  text-align: center;
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  color: #516074;
  padding: 20px 0 0px 0;
  margin: 0;
}

.h1 {
  line-height: 1.28571429em;
  margin: calc(2rem - 0.14285714em) 0 1rem;
  font-weight: 700;
  font-size: 2.2rem;
}

.text_justify {
  text-align: justify !important;
}

.text_left {
  text-align: left !important;
}

.line_height {
  line-height: 1.7;
}

.line_height_1 {
  line-height: 1.8;
}

.img {
  width: 100%;
}

.background_primary {
  background: linear-gradient(135deg, #2d74de 0%, #a833ff 68%, #a833ff 100%);
}

.background_white {
  background: white !important;
}

.background_gray {
  background: #f2f4f5 !important;
}

.p {
  color: #595f67 !important;
}

.pointer {
  cursor: pointer;
}

.centrado {
  display: flex;
  align-items: center;
}

.left {
  text-align: left !important;
}

.center {
  text-align: center !important;
}

.mb_1 {
  margin-bottom: 1rem;
}

.mb_2 {
  margin-bottom: 2rem;
}

.mt_1 {
  margin-top: 1rem;
}

.mt_2 {
  margin-top: 2rem;
}

.mt_3 {
  margin-top: 4rem;
}

.margin_auto {
  margin: 0 auto;
}

.paddings {
  padding-left: 15px;
  padding-right: 15px;
}

.padding_top {
  padding-top: 100px;
}

.sin_margen {
  margin: 0px;
}

.form {
  bottom: 15px;
  width: inherit !important;
  border-radius: 10px !important;
  position: relative;
  text-align: left;
  margin: 25px auto 0;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  background: #fff !important;
}

.form_h1 {
  text-align: center;
  margin: 0px;
  margin-bottom: 20px;
  font-size: 25px;
}

.input {
  padding: 15px;
  font-size: 14px;
  font-weight: 300;
  background-color: #fcfcfd;
  border: 1px solid #d9d9e1;
  color: #444444;
  border-radius: 5px;
  padding: 5px 20px;
  width: 100%;
  margin-bottom: 15px;
  font-family: "Roboto", sans-serif;
  height: 38px;
  box-shadow: none;
  transition: "all .2s ease";
}

.nombres {
  margin-bottom: 0px;
}

.info {
  cursor: pointer;
  font-family: arial, sans-serif;
  font-size: 16px;
  text-align: center;
  background: #ea512e !important;
  border: 0 !important;
  border-radius: 10px !important;
  color: #fff !important;
  padding: 15px !important;
  width: 100%;
}

.margin_top {
  margin-top: 100px;
}

.align_y {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.call_to_action {
  background-color: orange !important;
  color: #fff !important;
  padding: 13px !important;
  font-weight: 700 !important;
  margin-right: 20px;
  font-size: 16px;
  border-radius: 23px;
}

.border_sup {
  border-top-left-radius: 23px;
  border-top-right-radius: 23px;
}

.sin_padding {
  padding-left: 0px;
  padding-right: 0px;
}

.btn_404 {
  background-color: white !important;
  color: purple !important;
  border: 1px solid purple;
  padding: 5px;
  font-weight: 700 !important;
  margin-right: 20px;
  font-size: 16px;
  border-radius: 23px;
}
